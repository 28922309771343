<template>
  <div class="vld-parent">
    <div v-if="reqBusy">
      <loading :active="true" :is-full-page="false" />
    </div>
    <!-- <add-single-scooter /> -->

    <div
      v-if="details"
      class="grid space-x-2 space-y-2 lg:grid-cols-7 md:grid-cols-4"
    >
      <div class="lg:col-span-4 md:col-span-4">
        <div class="col-span-4 grid-grid-cols-1">
          <info-grid
            :title="
              $t(
                'components.vehicleDetailsManagement.diveIntoTab.profile.grid.basicInfo.gridTitle'
              )
            "
            gridClass="sm:grid-cols-1 xs:grid-cols-2"
            :data="getProfile()"
            :editPermissionStatus="$acl.canUpdate('vehicles')"
            @edit="onOpenES({ primaryKey: $route.params.id })"
          >
            <template v-slot:subheader>
              <div class="flex items-center -ml-5">
                <VueQrcode :value="getQrCodeURL" :options="{ width: 160 }" />
                <div class="ml-4">
                  <div class="cursor-pointer" @click="copyToClipBoard()">
                    <oto-typography text="QR Code" variant="two" />
                    <i class="ml-2 far fa-copy"></i>
                  </div>
                  <t-input
                    class="-mx-4 -mt-4 break-all"
                    :value="getQrCode"
                    ref="code"
                    variant="untraceable"
                    readonly="readonly"
                  />
                </div>
              </div>
            </template>
            <template v-slot:footer>
              <div class="grid grid-cols-2 py-3">
                <oto-typography
                  :text="
                    $t(
                      'components.vehicleDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.vehicleSpeed'
                    )
                  "
                  variant="two"
                />
                <oto-typography :text="getSpeed" variant="three" />
              </div>
              <div class="grid grid-cols-2 py-3">
                <oto-typography
                  :text="
                    $t(
                      'components.vehicleDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.description'
                    )
                  "
                  variant="two"
                />
                <oto-typography :text="getDescription" variant="three" />
              </div>
            </template>
          </info-grid>
        </div>

        <content-section class="py-6">
          <div class="mb-4">
            <h3 class="text-xl font-semibold uppercase text-oDark">
              {{
                $t(
                  'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.gridTitle'
                )
              }}
            </h3>
          </div>

          <div class="grid items-center grid-cols-2 py-2">
            <oto-typography
              :text="
                $t(
                  'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.name'
                )
              "
              variant="two"
            />
            <oto-typography
              :text="details.lock.name"
              fallback="--"
              variant="three"
            />
          </div>

          <div class="grid items-center grid-cols-2 py-2">
            <oto-typography
              :text="
                $t(
                  'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.lockIMEI'
                )
              "
              variant="two"
            />
            <oto-typography
              :text="details.lock.lock_id"
              fallback="--"
              variant="three"
            />
          </div>

          <div class="grid items-center grid-cols-2 py-2">
            <oto-typography
              :text="
                $t(
                  'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.lockID'
                )
              "
              variant="two"
            />
            <oto-typography
              :text="`#${details.lock.id}`"
              fallback="--"
              variant="three"
              style="color:blue"
            />
          </div>

          <div class="grid items-center grid-cols-2 py-2">
            <oto-typography
              :text="
                $t(
                  'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.ioTCategory'
                )
              "
              variant="two"
            />
            <oto-typography
              :text="details.lock.iot_category"
              fallback="--"
              variant="three"
            />
          </div>

          <div v-if="isSegwayIOT" class="grid items-center grid-cols-2 py-2">
            <div>
              <oto-typography
                :text="
                  $t(
                    'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.unlockCount'
                  )
                "
                variant="two"
              />
            </div>
            <div class=" flex">
              <oto-typography
                :text="`${details.lock.unlock_count - 2000}`"
                fallback="--"
                variant="three"
              />
              <div
                class="cursor-pointer pl-3"
                @click="handleUnlockCountEdit('unlock_count')"
              >
                <svg
                  class="w-4 h-5"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>

          <div class="grid items-center grid-cols-2 py-2">
            <oto-typography
              :text="
                $t(
                  'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.firmwareKey'
                )
              "
              variant="two"
            />
            <oto-typography
              :text="details.lock.firmware_key"
              fallback="--"
              variant="three"
            />
          </div>

          <div class="grid items-center grid-cols-2 py-2">
            <oto-typography
              :text="
                $t(
                  'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.firmwareType'
                )
              "
              variant="two"
            />
            <oto-typography
              :text="details.lock.firmware_type"
              fallback="--"
              variant="three"
            />
          </div>

          <div class="grid items-center grid-cols-2 py-2">
            <oto-typography
              :text="
                $t(
                  'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.description'
                )
              "
              variant="two"
            />
            <oto-typography
              :text="details.lock.description"
              fallback="--"
              variant="three"
            />
          </div>

          <div class="grid grid-cols-2 py-2">
            <oto-typography
              :text="
                $t(
                  'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.lockQRCode'
                )
              "
              variant="two"
            />
            <oto-typography
              :text="details.lock.qr_code"
              fallback="--"
              variant="three"
              class="break-all"
            />
          </div>

          <div class="grid py-2 md:grid-cols-6 sm:grid-cols-3">
            <div class="md:col-span-3 sm:col-span-2">
              <oto-typography
                :text="
                  $t(
                    'components.vehicleDetailsManagement.diveIntoTab.profile.grid.assignedLockInfo.infoTitle.macAddress'
                  )
                "
                variant="two"
              />
            </div>
            <div class="md:col-span-2 sm:col-span-2">
              <oto-typography
                :text="details.lock.lock_mac"
                fallback="--"
                variant="three"
              />
            </div>
            <div class="md:col-span-1 sm:col-span-1 md:ml-auto sm:ml-0">
              <oto-download-button
                variant="green"
                @click="handleDownloading('mac')"
              >
                <div class="w-6 h-5 vld-parent">
                  <loading
                    v-if="downloading.mac"
                    :active.sync="downloading.mac"
                    color="#fff"
                    :height="20"
                    :width="20"
                    :opacity="0"
                    :enforce-focus="false"
                    :is-full-page="false"
                  />
                  <div v-else>
                    <i class="fas fa-download"></i>
                  </div>
                </div>
              </oto-download-button>
            </div>
          </div>
        </content-section>
        <div class="col-span-4 px-10 py-6 mt-4 bg-white rounded shadow">
          <VehicleTagView :id="id" />
        </div>

        <div class="col-span-4">
          <info-grid
            :title="
              $t(
                'components.vehicleDetailsManagement.diveIntoTab.profile.grid.domainInfo.gridTitle'
              )
            "
            :data="getDomain()"
            :edit-button="false"
            grid-class="grid-cols-1"
          />
        </div>
      </div>

      <div class="grid grid-cols-2 md:col-span-4 lg:col-span-3">
        <div class="col-span-2 bg-white">
          <h3 class="px-4 py-4 text-xl font-semibold uppercase text-oDark">
            {{
              $t(
                'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleLocationInfo.gridTitle'
              )
            }}
          </h3>
          <div class="h-80 relative">
            <gmap-map
              ref="map"
              :zoom="17"
              :center="latLng"
              map-type-id="terrain"
              style="width: 100%; height: 100%;"
            >
              <gmap-marker
                :position="latLng"
                :draggable="false"
                :animation="1"
                :icon="getStyledMarkerIcon(details.lock.power_level)"
                style="width: 30px; height: 30px;"
              />
              <template v-if="getParkingAreas.length > 0 && parkingMarkerOpen">
                <gmap-marker
                  :key="index"
                  v-for="(area, index) in getParkingAreas"
                  :position="getLatLng(area.center_coords)"
                  :icon="{
                    url: require(`@/assets/img/areas_pin/P_icon.png`),
                    scaledSize: { width: 45, height: 55 },
                  }"
                  @click="toggleMarker(area, -1, 'P')"
                />
              </template>
              <template
                v-if="getRestrictedAreas.length > 0 && restrictedMarkerOpen"
              >
                <gmap-marker
                  :key="index"
                  v-for="(area, index) in getRestrictedAreas"
                  :position="getLatLng(area.center_coords)"
                  :icon="{
                    url: require(`@/assets/img/areas_pin/R_icon.png`),
                    scaledSize: { width: 45, height: 55 },
                  }"
                  @click="toggleMarker(area, -1, 'R')"
                />
              </template>
              <template
                v-if="getSlowSpeedAreas.length > 0 && slowSpeedMarkerOpen"
              >
                <gmap-marker
                  :key="index"
                  v-for="(area, index) in getSlowSpeedAreas"
                  :position="getLatLng(area.center_coords)"
                  :icon="{
                    url: require(`@/assets/img/areas_pin/S_icon.png`),
                    scaledSize: { width: 45, height: 55 },
                  }"
                  @click="toggleMarker(area, -1, 'S')"
                />
              </template>
              <gmap-info-window
                :options="infoOptions"
                :position="infoWindowPos"
                :opened="infoWinOpen"
                @closeclick="infoWinOpen = false"
              >
                <div v-html="infoContent"></div>
              </gmap-info-window>
            </gmap-map>
            <div class="area-info" v-if="getMarkerControlCounter > 0">
              <div
                class="flex flex-wrap items-center justify-between px-4 area-body"
              >
                <button
                  v-if="getParkingAreas.length > 0"
                  @click="parkingMarkerOpen = !parkingMarkerOpen"
                  class="flex items-center justify-between mx-1"
                >
                  <img
                    src="@/assets/img/areas_pin/P_icon.png"
                    width="20px"
                    class="mr-2"
                  />
                  {{ parkingMarkerOpen ? 'Hide' : 'Show' }}
                </button>

                <button
                  v-if="getRestrictedAreas.length > 0"
                  @click="restrictedMarkerOpen = !restrictedMarkerOpen"
                  class="flex items-center justify-between mx-1"
                >
                  <img
                    src="@/assets/img/areas_pin/R_icon.png"
                    width="20px"
                    class="mr-2"
                  />
                  {{ restrictedMarkerOpen ? 'Hide' : 'Show' }}
                </button>

                <button
                  v-if="getSlowSpeedAreas.length > 0"
                  @click="slowSpeedMarkerOpen = !slowSpeedMarkerOpen"
                  class="flex items-center justify-between mx-1"
                >
                  <img
                    src="@/assets/img/areas_pin/S_icon.png"
                    width="20px"
                    class="mr-2"
                  />
                  {{ slowSpeedMarkerOpen ? 'Hide' : 'Show' }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-span-2 mt-2 bg-white">
          <info-grid
            :title="
              $t(
                'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.gridTitle'
              )
            "
            :data="getVehicleStatus()"
            :edit-button="false"
            grid-class="grid-cols-1"
          >
            <template #subheader>
              <div class="grid items-center grid-cols-2 py-2">
                <oto-typography
                  :text="
                    $t(
                      'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.infoTitle.status'
                    )
                  "
                  variant="two"
                />

                <TripStatusButton
                  :text="getTripStatus ? 'On Rent' : 'Free'"
                  :variant="getTripStatus ? 'purple' : 'green'"
                />
              </div>
            </template>
          </info-grid>
        </div>

        <div class="col-span-2 px-10 py-6 mt-4 bg-white">
          <h3 class="pb-4 text-xl font-bold uppercase text-oDark">
            {{
              $t(
                'components.vehicleDetailsManagement.diveIntoTab.profile.grid.enabledFlagInfo.gridTitle'
              )
            }}
          </h3>
          <vehicle-flags
            :flags="getFlags"
            :event-enabled="false"
            :fallback="true"
          />
        </div>

        <div class="col-span-2">
          <content-section class="py-6">
            <div class="mb-4">
              <h3 class="text-xl font-semibold uppercase text-oDark">
                {{
                  $t(
                    'components.vehicleDetailsManagement.diveIntoTab.profile.grid.networkInfo.gridTitle'
                  )
                }}
              </h3>
            </div>

            <div class="grid items-center grid-cols-2 py-2">
              <oto-typography
                :text="
                  $t(
                    'components.vehicleDetailsManagement.diveIntoTab.profile.grid.networkInfo.infoTitle.simPhoneNumber'
                  )
                "
                variant="two"
              />
              <oto-typography
                :text="details.sim_number"
                fallback="--"
                variant="three"
              />
            </div>

            <div class="grid py-2 md:grid-cols-6 sm:grid-cols-3">
              <div class="md:col-span-3 sm:col-span-2">
                <oto-typography
                  :text="
                    $t(
                      'components.vehicleDetailsManagement.diveIntoTab.profile.grid.networkInfo.infoTitle.simICCIDNumber'
                    )
                  "
                  variant="two"
                />
              </div>
              <div class="md:col-span-2 sm:col-span-2">
                <oto-typography
                  :text="details.lock.iccid"
                  fallback="--"
                  variant="three"
                  class="break-all"
                />
              </div>

              <div class="md:col-span-1 sm:col-span-1 md:ml-auto sm:ml-0">
                <oto-download-button
                  variant="green"
                  @click="handleDownloading('sim')"
                >
                  <div class="w-6 h-5 vld-parent">
                    <loading
                      v-if="downloading.sim"
                      :active.sync="downloading.sim"
                      color="#fff"
                      :height="20"
                      :width="20"
                      :opacity="0"
                      :enforce-focus="false"
                      :is-full-page="false"
                    />
                    <div v-else>
                      <i class="fas fa-download"></i>
                    </div>
                  </div>
                </oto-download-button>
              </div>
            </div>

            <div class="grid items-center grid-cols-2 py-2">
              <oto-typography
                :text="
                  $t(
                    'components.vehicleDetailsManagement.diveIntoTab.profile.grid.networkInfo.infoTitle.networkStrength'
                  )
                "
                variant="two"
              />

              <NetworkStrength :value="details.lock.network_signal" />
            </div>
          </content-section>
        </div>
      </div>
    </div>

    <!-- <button @click="onOpenES({ primaryKey: null })">Add</button> -->
    <!-- <button @click="onOpenES({ primaryKey: $route.params.id })">Edit</button> -->
    <VehicleAddEdit
      :es-id="addEdit.esId"
      :stepper-mode="addEdit.stepperMode"
      :stepper-step="addEdit.stepperStep"
      :primary-key="addEdit.primaryKey"
      :primary-key-lock="addEdit.primaryKeyLock"
      :raw-data="addEdit.rawData"
      :busy="addEdit.busy"
      @refresh="fetchVehicleData"
    />

    <EditVehicleInfo
      :primaryKey="details.id"
      :profileData="details"
      :singleUpdateKey="singleUpdateKey"
      @refresh="fetchVehicleData"
    />
  </div>
</template>

<script>
// TODO: Flag > right side
// TODO: Tags
import { useEndpoints /* useVehicleDetails */ } from '@/composables'
import VehicleFlagHelperMixin from '@/mixins/vehicleFlagHelperMixin'

import InfoGrid from '@/components/layout/InfoGrid'
import ContentSection from '@/components/layout/ContentSection'
// import OtoEditButton from "@/components/ui/OtoEditButton";
import VueQrcode from '@chenfengyuan/vue-qrcode'
import OtoTypography from '@/components/ui/OtoTypography'
import OtoDownloadButton from '@/components/ui/OtoDownloadButton'
import VehicleTagView from '@/views/vehicle/VehicleTagView.vue'
import VehicleFlags from '@/components/badge/VehicleFlags'

// import { EventBus } from '@/utils/EventBus'
// import AddSingleScooter from '@/views/scooter/AddSingleScooter.vue'
// import { SingleScooterConfig } from '@/config/SingleScooterConfig'
// import { getLocalDateFromUTC } from "@/utils/ConvertDate";
import { TripStatusButton } from '@/composites/trip'

import { NetworkStrength } from '@/components/badge'
import { VehicleAddEdit } from '@/composites/vehicle'
import { mapGetters, mapState } from 'vuex'
import { getTimeAgo, getUTCAwareTime } from '@/utils'
import { gmapApi } from 'vue2-google-maps'
import EditVehicleInfo from './EditVehicleInfo.vue'

export default {
  name: 'ViewVehicleProfile',
  mixins: [VehicleFlagHelperMixin],
  components: {
    InfoGrid,
    ContentSection,
    VueQrcode,
    OtoTypography,
    OtoDownloadButton,
    VehicleFlags,
    // AddSingleScooter,
    TripStatusButton,
    NetworkStrength,
    VehicleAddEdit,
    VehicleTagView,
    EditVehicleInfo,
  },

  data() {
    return {
      // isLoading: false,
      downloading: {
        sim: false,
        mac: false,
      },
      fallbackText: '--',
      singleUpdateKey: '',
      // todo: export from another file & import it
      addEdit: {
        // EdgeStack component
        esId: 'vehicle-add-edit',

        // XStepper component
        stepperMode: 'strict',
        stepperStep: 1,

        // Data
        busy: true,
        rawData: null,
        primaryKey: null,
      },
      parkingMarkerOpen: false,
      restrictedMarkerOpen: false,
      slowSpeedMarkerOpen: false,
      infoWinOpen: false,

      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -70,
        },
      },
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      map: null,
      serviceDrawingOptions: {
        fillColor: '#4aff5c20',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#00b44d',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 1,
      },
      parkingDrawingOptions: {
        fillColor: 'rgba(30, 152, 254, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#00acff',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 2,
      },
      restrictedDrawingOptions: {
        fillColor: 'rgba(255, 0, 0, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#ff0000',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 3,
      },
      slowSpeedDrawingOptions: {
        fillColor: 'rgba(204, 204, 20, 0.13)',
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#CCCC00',
        clickable: false,
        draggable: false,
        editable: false, // TODO: make this work with wrapper func
        zIndex: 4,
      },
    }
  },

  computed: {
    google: gmapApi,
    id() {
      return this.$route.params.id
    },
    ...mapState({
      reqBusy: (state) => state.vehicleDetails.reqBusy,
      details: (state) => state.vehicleDetails.details,
      latLng: (state) => state.vehicleDetails.latLng,
      geocoderResponse: (state) => state.vehicleDetails.geocoderResponse,
    }),
    ...mapGetters('auth', ['organizationInfo']),
    ...mapGetters('vehicleDetails', ['getFlags']),
    getMarkerControlCounter() {
      let counter = 0
      if (this.getParkingAreas.length > 0) {
        counter++
      }
      if (this.getRestrictedAreas.length > 0) {
        counter++
      }
      if (this.getSlowSpeedAreas.length > 0) {
        counter++
      }
      console.log('getMarkerControlCounter', counter)
      return counter
    },
    getQrCodeURL() {
      const orgDomain = JSON.parse(localStorage.getItem('organization')).domain
      return `https://${orgDomain}/${this.details?.qr_code || '--'}`
    },
    getQrCode() {
      return this.details?.qr_code || '--'
    },
    getSpeed() {
      return (
        this.details?.geofence?.speed_limit?.speed_choice || this.fallbackText
      )
    },
    getParkingAreas() {
      return this.details?.parking_areas_in_geofence
    },
    getRestrictedAreas() {
      return this.details?.restricted_areas_in_geofence
    },
    getSlowSpeedAreas() {
      return this.details?.slowzone_areas_in_geofence
    },

    getDescription() {
      return this.details?.description || this.fallbackText
    },

    getTripStatus() {
      return this?.details?.status_flags?.is_on_ride || false
    },
    getVehicleTypeIcon() {
      let icon = ''

      if (['P', 'PP'].includes(this.details.bike_category)) {
        icon = 'scooter'
      }
      if (['S'].includes(this.details.bike_category)) {
        icon = 'bike'
      }
      if (['E'].includes(this.details.bike_category)) {
        icon = 'ebike'
      }
      if (['C', 'K', 'M'].includes(this.details.bike_category)) {
        icon = 'coco'
      }
      return require(`@/assets/img/vehicle-icons/${icon}_${'dark'}.svg`)
    },
    isSegwayIOT() {
      if (
        this.$store.state.vehicleDetails.details.lock.iot_category.split(
          '_'
        )[0] === 'SEGWAY'
      ) {
        return true
      } else {
        return false
      }
    },
  },

  async mounted() {
    this.$nextTick(() => {
      this.initDraw()
    })
  },

  methods: {
    getStyledMarkerIcon(batteryPercentage) {
      const markerIcon = this.createMarkerIcon(batteryPercentage)
      return markerIcon
    },
    createMarkerIcon(batteryPercentage) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      const centerX = canvas.width / 2
      const centerY = canvas.height / 2
      const iconRadius = 20 // Radius of the circular progress bar
      const markerWidth = 10 // Width of the marker triangle
      const markerHeight = 10 // Height of the marker triangle
      const startAngle = -Math.PI / 2 // Start at the top
      const endAngle = (2 * Math.PI * batteryPercentage) / 100 + startAngle // Calculate the end angle based on battery percentage

      // Clear the canvas
      context.clearRect(0, 0, canvas.width, canvas.height)

      // Draw the marker triangle below the circular progress bar
      context.beginPath()
      context.moveTo(centerX - markerWidth / 2, centerY + iconRadius)
      context.lineTo(centerX + markerWidth / 2, centerY + iconRadius)
      context.lineTo(centerX, centerY + iconRadius + markerHeight)
      context.closePath()
      context.fillStyle = '#000' // Set the marker color
      context.fill()

      // Draw the white background circle
      context.beginPath()
      context.arc(centerX, centerY, iconRadius + 5, 0, 2 * Math.PI)
      context.fillStyle = '#000' // Set the background color to white
      context.fill()

      // Draw the outer circle
      context.beginPath()
      context.arc(centerX, centerY, iconRadius, 0, 2 * Math.PI)
      context.strokeStyle = '#fff'
      context.lineWidth = 5
      context.stroke()

      // Draw the progress arc
      context.beginPath()
      context.arc(centerX, centerY, iconRadius, startAngle, endAngle)
      context.strokeStyle = this.getPowerFillColor(
        Math.round(batteryPercentage)
      ) // Change color based on battery status
      context.lineWidth = 5
      context.stroke()

      // Display the battery percentage as text
      context.font = 'bold 14px Arial'
      context.fillStyle = 'white'
      context.textAlign = 'center'
      context.textBaseline = 'middle'
      context.fillText(Math.round(batteryPercentage) + '%', centerX, centerY)

      return canvas.toDataURL() // Convert the canvas to a data URL for the icon
    },

    getPowerFillColor(powerLevel) {
      let color = ''
      if (powerLevel < 25) {
        color = '#ff0000'
      } else if (powerLevel >= 25 && powerLevel < 70) {
        color = '#ffaf00'
      } else if (powerLevel >= 70 && powerLevel <= 100) {
        color = '#0ac84b'
      }
      return color
    },
    getAreaTypeLink(type) {
      if (type === 'P') {
        return `/geofence/parking-areas/`
      }
      if (type === 'R') {
        return `/geofence/restricted-areas/`
      }
      if (type === 'S') {
        return `/geofence/slow-zone-areas/`
      }
    },
    toggleMarker(area, idx, areaType) {
      this.infoWinOpen = true
      this.infoWindowPos = this.getLatLng(area.center_coords)
      if (this.currentMidx == idx) {
        // this.infoWinOpen = !this.infoWinOpen
      } else {
        this.currentMidx = idx
      }
      this.infoContent = this.getInfoWindowContent(area, areaType)
    },
    getInfoWindowContent: function(item, areaType) {
      //work from here

      return `
        <div class="card">
          <div class="card-content">
            <div class="flex items-center h-6 pl-2">
              <span class="card-title">Area Name:</span>
              <span class="ml-2 card-text ">
              <a href="${this.getAreaTypeLink(areaType)}${
        item.id
      }" class="font-semibold text-blue-600 capitalize " target="_blank"> ${
        item.name == undefined ? ' ' : item.name
      } </a>
              </span>
            </div>
            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">Address:</span>
              <span class="ml-2 card-text">${item.address}</span>
            </div>
            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">Latitude:</span>
              <span class="ml-2 card-text">${
                this.getLatLng(item.center_coords).lat
              }</span>
            </div>

            <div class="flex items-center h-6 pl-2">
              <span class="card-title">Longitude:</span>
              <span class="ml-2 card-text">${
                this.getLatLng(item.center_coords).lng
              }</span>
            </div>

            <div class="flex items-center h-6 pl-2 mt-1">
              <span class="card-title">Active Status:</span>
              <span class="ml-2 card-text font-semibold">${
                item.is_active
                  ? '<span class="text-green-600">Yes<span>'
                  : '<span class="text-gray-600">No<span>'
              }</span>
            </div>
             </div>
        </div>
      `
    },
    getLatLng(position) {
      if (position == '' || position == null)
        return {
          lat: 0,
          lng: 0,
        }
      let lat = Number(position.split(',')[0])
      let lng = Number(position.split(',')[1])
      return {
        lat: lat,
        lng: lng,
      }
    },
    initDraw() {
      const self = this
      setTimeout(() => {
        if (!self.$refs.map) {
          this.initDraw()
          return
        }
        self.$refs.map.$mapPromise.then((map) => {
          this.map = map
          this.drawServiceArea()
          if (this.details?.parking_areas_in_geofence.length > 0) {
            this.drawParkingArea()
          }
          if (this.details?.restricted_areas_in_geofence.length > 0) {
            this.drawRestrictedArea()
          }
          if (this.details?.slowzone_areas_in_geofence.length > 0) {
            this.drawSlowSpeedArea()
          }
        })
      }, 1000)
    },
    drawServiceArea() {
      const overlay = this.parseToOverlay(this.details?.geofence.coords)

      // Get bounds
      const bounds = new this.google.maps.LatLngBounds()
      for (const path of overlay) {
        bounds.extend(path)
      }

      // draw polygon
      const parsedPolygon = new this.google.maps.Polygon({
        paths: overlay,
        ...this.serviceDrawingOptions,
      })
      parsedPolygon.setMap(this.map)
    },
    drawParkingArea() {
      this.details?.parking_areas_in_geofence.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.parkingDrawingOptions,
        })
        parsedPolygon.setMap(this.map)
      })
    },
    drawRestrictedArea() {
      this.details?.restricted_areas_in_geofence.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.restrictedDrawingOptions,
        })
        parsedPolygon.setMap(this.map)
      })
    },
    drawSlowSpeedArea() {
      this.details?.slowzone_areas_in_geofence.forEach((area) => {
        const overlay = this.parseToOverlay(area.coords)

        // Get bounds
        const bounds = new this.google.maps.LatLngBounds()
        for (const path of overlay) {
          bounds.extend(path)
        }

        // draw polygon
        const parsedPolygon = new this.google.maps.Polygon({
          paths: overlay,
          ...this.slowSpeedDrawingOptions,
        })
        parsedPolygon.setMap(this.map)
      })
    },
    parseToOverlay(coords) {
      if (!coords) return
      var coordsMorph = coords.replaceAll('{', '')
      coordsMorph = coordsMorph.replaceAll('}', '')
      var coordsArr = coordsMorph.split(',')
      if (!coordsArr.length % 2 === 0) {
        if (coordsArr[coordsArr.length - 1] == ',') {
          console.log('Hit pop')
          coordsArr.pop()
        }
      }
      // console.log("")
      var payload = []
      for (var i = 0; i < coordsArr.length; i = i + 2) {
        // console.log("lat", coordsArr[i])
        // console.log("lon", coordsArr[i+1])
        const lat = parseFloat(coordsArr[i])
        const lng = parseFloat(coordsArr[i + 1])
        if (isNaN(lat) || isNaN(lng)) continue
        payload.push({
          lat: lat,
          lng: lng,
        })
      }
      return payload
    },
    async onOpenES({ primaryKey }) {
      if (typeof primaryKey === 'string') {
        this.addEdit.busy = true
        this.$edgeStack.open(this.addEdit.esId)

        const vReq = this.$http.get(useEndpoints.vehicle.details(primaryKey))

        const requests = [vReq]

        await this.$http
          .all(requests)
          .then(
            this.$http.spread((...responses) => {
              let data = {}
              const [vRes] = responses
              console.log(vRes.data, 'vRes')

              data = { ...vRes.data }

              this.addEdit.stepperMode = 'free'

              this.addEdit.busy = false
              this.addEdit.rawData = data
              this.addEdit.primaryKey = primaryKey // for vehicle
              this.addEdit.primaryKeyLock = data.lock.id // for lock
            })
          )
          .catch((err) => {
            console.warn({ err })

            this.$edgeStack.close(this.addEdit.esId)
            this.$notify({
              group: 'bottomRight',
              type: 'error',
              title: `Server Error`,
              text: 'Failed to retrieve data. Please try again.',
            })
          })

        return
      }

      this.addEdit.stepperMode = 'strict'

      this.addEdit.busy = false
      this.addEdit.rawData = null // for vehicle
      this.addEdit.primaryKey = null // for vehicle
      this.addEdit.primaryKeyLock = null // for lock

      this.$edgeStack.open(this.addEdit.esId)
      // this.$xStepper.navigate(this.addEdit.esId).to(4)
    },

    async fetchVehicleData() {
      this.isLoading = true
      this.$store
        .dispatch('vehicleDetails/fetchData', {
          type: 'details',
          primaryKey: this.id,
        })
        .then(() => {
          this.isLoading = false
        })
    },

    getTypeAliasFromData(data) {
      let aliases = {
        S: 'Bike',
        P: 'Scooter',
        PP: 'Scooter Pro',
        E: 'Ebike',
        C: 'Super Coco',
        K: 'Kenota',
        M: 'Moped',
      }
      return data in aliases ? aliases[data] : null
    },
    getProfile() {
      let data = []
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.vehicleID'
        ),
        value: this.details?.id || this.fallbackText,
      })
      data.push({
        key: 'vehicleQRCodeURL',
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.vehicleQRCodeURL'
        ),
        value: this.getQrCodeURL || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.vehicleName'
        ),
        value: this.details?.name || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.assignedFleet'
        ),
        value: this.details?.fleet?.name || this.fallbackText,
        id: this.details?.fleet?.id,
      })
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.assignedGeofence'
        ),
        value: this.details?.geofence?.name || this.fallbackText,
        id: this.details?.geofence?.id,
      })
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.vehicleType'
        ),
        value:
          this.getTypeAliasFromData(this.details?.bike_category) ||
          this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.vehicleSerialNumber'
        ),
        value: this.details?.bike_short_id || this.fallbackText,
      })
      return data
    },
    getDomain() {
      let data = []
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.domainInfo.infoTitle.domain'
        ),
        value: this.organizationInfo?.domain || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.domainInfo.infoTitle.port'
        ),
        value: this.organizationInfo?.port || this.fallbackText,
      })
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.domainInfo.infoTitle.registeredAt'
        ),
        value: this.organizationInfo
          ? this.$localTime(this.organizationInfo.created_at)
          : this.fallbackText,
      })
      return data
    },
    getVehicleStatus() {
      let data = []
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.infoTitle.currentLocation'
        ),
        value: this.geocoderResponse?.display_name,
        color: '#0000FF',
      })
      data.push({
        key: 'currentLatLong',
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.infoTitle.currentLatLong'
        ),
        value: this.details?.location,
        color: '#0000FF',
      })
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.infoTitle.locationSimulationMode'
        ),
        value: this.details?.is_under_simulation ? 'On' : 'Off',
      })
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.infoTitle.simulatedLatLong'
        ),
        value: this.details?.simulated_new_loc_coord,
      })
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.infoTitle.currentSpeed'
        ),
        value: this.details?.current_speed,
      })
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.infoTitle.availability'
        ),
        value: this.details?.is_available ? 'Yes' : 'No',
      })
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.infoTitle.lockStatus'
        ),
        value: this.details?.lock?.is_locked ? 'Locked' : 'Unlocked',
      })
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.infoTitle.cableLockStatus'
        ),
        value: this.details?.lock?.cable_is_locked ? 'Locked' : 'Unlocked',
      })
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.infoTitle.tripStatus'
        ),
        value: this.details?.status_flags?.is_on_ride
          ? 'On Rent'
          : 'Not On Rent',
      })
      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.infoTitle.chargingStatus'
        ),
        value: this.details?.status_flags?.is_charging
          ? 'Connected'
          : 'Disconnected',
      })

      let toFormattedDate

      if (this.details?.lock?.last_heartbeat_time) {
        let timeString
        if (this.details.lock.last_heartbeat_time.toString().length === 10) {
          timeString = new Date(this.details.lock.last_heartbeat_time * 1000)
        } else {
          timeString = new Date(
            Number(
              this.details.lock.last_heartbeat_time.toString().slice(0, 10)
            ) * 1000
          )
        }
        toFormattedDate = getUTCAwareTime(timeString.toISOString())
      }

      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.infoTitle.lastHeartbeatAt'
        ),
        value:
          `${toFormattedDate} (${getTimeAgo(toFormattedDate)} ago)` || '--',
      })

      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.infoTitle.lastRideAt'
        ),
        value: this.details?.lock?.last_ride_at
          ? `${getUTCAwareTime(this.details.lock.last_ride_at)} (${getTimeAgo(
              getUTCAwareTime(this.details.lock.last_ride_at)
            )} ago)`
          : '--',
      })

      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.infoTitle.lastAlarmedAt'
        ),
        value: this.details.last_alarmed_at
          ? `${getUTCAwareTime(this.details.last_alarmed_at)} (${getTimeAgo(
              getUTCAwareTime(this.details.last_alarmed_at)
            )} ago)`
          : '--',
      })

      data.push({
        text: this.$t(
          'components.vehicleDetailsManagement.diveIntoTab.profile.grid.vehicleStatusInfo.infoTitle.lastLocationUpdatedAt'
        ),
        value: this?.details?.last_loc_updated_at
          ? `${getUTCAwareTime(this.details.last_loc_updated_at)} (${getTimeAgo(
              getUTCAwareTime(this.details.last_loc_updated_at)
            )} ago)`
          : '--',
      })
      return data
    },
    copyToClipBoard() {
      this.$refs.code?.select()
      this.$refs.code?.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$notify(
        {
          group: 'generic',
          type: 'success',
          title: 'Code Copied',
          text: 'The QR code has been copied to your clipboard',
        },
        2000
      )
    },
    // handleEditing() {
    //   // alert("editing");
    //   var item = {
    //     step: 1,
    //     id: this.id,
    //   }
    //   EventBus.$emit(SingleScooterConfig.events.editingData, item)
    //   dispatchEvent(new Event(SingleScooterConfig.events.sorToggle))
    // },
    async handleDownloading(type) {
      if (type === 'sim') {
        this.downloading.sim = true
        await setTimeout(() => {
          this.downloading.sim = false
        }, 2500)
      }
      if (type === 'mac') {
        this.downloading.mac = true
        await setTimeout(() => {
          this.downloading.mac = false
        }, 2500)
      }
    },
    setLatLngData(location) {
      let [iLat, iLng] = location.split(',')
      this.center = {
        lat: Number(iLat),
        lng: Number(iLng),
      }
      this.marker = {
        lat: Number(iLat),
        lng: Number(iLng),
      }
    },
    handleUnlockCountEdit(key) {
      this.singleUpdateKey = key
      console.log('handleUnlockCountEdit')
      setTimeout(() => {
        this.$modal.show(`editVehicleInfo-${this.details.id}`)
      }, 100)
    },
  },
}
</script>

<style lang="scss" scoped>
.fvc-code {
  display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  background: #f6f9ff;
  height: 86px;
  width: 100%;
}
.web-link-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 42px;
  background: rgba(243, 244, 246, 1);
  border: 1px solid rgba(203, 213, 224, 1);
  margin-left: 0.25rem;
  border-radius: 0.25rem;
}
.area-info {
  position: absolute;
  bottom: 5px;
  width: 100%;
  height: 40px;
  display: flex;
  .area-body {
    height: 100%;
    margin: auto;
    background: white;
    border-radius: 5px;
    box-shadow: 1px 1px 6px 0px #757575bf;
  }
}
</style>
